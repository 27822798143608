define('ember-cli-mdc-dialog/components/mdc-dialog-title', ['exports', 'ember-cli-mdc-dialog/templates/components/mdc-dialog-title'], function (exports, _mdcDialogTitle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcDialogTitle.default,

    classNames: ['mdc-dialog__title'],

    tagName: 'h2'
  });
});