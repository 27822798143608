define('ember-cli-mdc-list/components/mdc-list-item-meta-icon', ['exports', 'ember-cli-mdc-list/components/mdc-list-item-meta', 'ember-cli-mdc-list/templates/components/mdc-list-item-meta-icon'], function (exports, _mdcListItemMeta, _mdcListItemMetaIcon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mdcListItemMeta.default.extend({
    layout: _mdcListItemMetaIcon.default,

    classNames: ['material-icons'],

    icon: Ember.computed('params.[]', function () {
      return this.get('params')[0];
    })
  }).reopenClass({
    positionalParams: 'params'
  });
});