define('ember-cli-mdc-checkbox/components/mdc-checkbox-native-control', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Checkbox.extend({
    classNames: ['mdc-checkbox__native-control'],

    attributeBindings: ['form']
  });
});