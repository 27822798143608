define('ember-cli-mdc-textfield/components/mdc-textfield-helper-text', ['exports', 'ember-cli-mdc-textfield/templates/components/mdc-textfield-helper-text'], function (exports, _mdcTextfieldHelperText) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcTextfieldHelperText.default,

    tagName: 'p',

    classNames: ['mdc-text-field-helper-text'],

    classNameBindings: ['persistent:mdc-text-field-helper-text--persistent', 'validation:mdc-text-field-helper-text--validation-msg'],

    persistent: false,
    validation: false,

    didInsertElement() {
      this._super(...arguments);

      this.element.setAttribute('aria-hidden', true);
    }
  });
});