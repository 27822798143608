define('ember-cli-mdc-card/components/mdc-card-media', ['exports', 'ember-cli-mdc-card/templates/components/mdc-card-media'], function (exports, _mdcCardMedia) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcCardMedia.default,

    classNames: ['mdc-card__media'],
    classNameBindings: ['scaleClassName'],

    /// The scale for the media. Either 'square' or '16-9'.
    scale: null,

    scaleClassName: Ember.computed('scale', function () {
      const scale = this.get('scale');
      return Ember.isPresent('scale') ? `mdc-card__media--${scale}` : null;
    })
  });
});