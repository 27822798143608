define('ember-cli-mdc-button/components/mdc-button-label', ['exports', 'ember-cli-mdc-button/templates/components/mdc-button-label'], function (exports, _mdcButtonLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcButtonLabel.default,

    tagName: 'span',

    classNames: ['mdc-button__label']
  });
});