define('ember-cli-mdc-drawer/components/mdc-drawer-scrim', ['exports', 'ember-cli-mdc-drawer/templates/components/mdc-drawer-scrim'], function (exports, _mdcDrawerScrim) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcDrawerScrim.default,

    classNames: ['mdc-drawer-scrim']
  });
});