define('ember-cli-mdc-linear-progress/components/mdc-linear-progress-bar', ['exports', 'ember-cli-mdc-linear-progress/templates/components/mdc-linear-progress-bar'], function (exports, _mdcLinearProgressBar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcLinearProgressBar.default,

    classNames: ['mdc-linear-progress__bar'],
    classNameBindings: ['primary:mdc-linear-progress__primary-bar:mdc-linear-progress__secondary-bar'],

    primary: true
  });
});