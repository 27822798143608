define('ember-cli-mdc-top-app-bar/components/mdc-top-app-bar-row', ['exports', 'ember-cli-mdc-top-app-bar/templates/components/mdc-top-app-bar-row'], function (exports, _mdcTopAppBarRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcTopAppBarRow.default,

    classNames: ['mdc-top-app-bar__row']
  });
});