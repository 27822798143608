define('ember-cli-mdc-list/mixins/list-item', ['exports', 'ember-cli-mdc-ripple/mixins/ripple'], function (exports, _ripple) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_ripple.default, {
    classNames: ['mdc-list-item'],

    classNameBindings: ['selected:mdc-list-item--selected', 'activated:mdc-list-item--activated', 'disabled:mdc-list-item--disabled'],

    attributeBindings: ['role'],

    selected: false,

    activated: false,

    createRippleComponent: true,

    didInsertElement() {
      this._super(...arguments);

      if (this.get('selected')) {
        this.element.setAttribute('aria-selected', true);
        this.element.setAttribute('tabindex', 0);
      } else {
        this.element.removeAttribute('aria-selected');
        this.element.removeAttribute('tabindex');
      }
    }
  });
});