define('ember-cli-mdc-list/components/mdc-list-item-meta', ['exports', 'ember-cli-mdc-list/templates/components/mdc-list-item-meta'], function (exports, _mdcListItemMeta) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcListItemMeta.default,

    tagName: 'span',

    classNames: ['mdc-list-item__meta'],

    didInsertElement() {
      this._super(...arguments);

      this.element.setAttribute('aria-hidden', true);
    }
  });
});