define('ember-cli-mdc-card/components/mdc-card-media-content', ['exports', 'ember-cli-mdc-card/templates/components/mdc-card-media-content'], function (exports, _mdcCardMediaContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcCardMediaContent.default,

    classNames: ['mdc-card__media-content']
  });
});