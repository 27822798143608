define('ember-cli-mdc-card/components/mdc-card-action-icons', ['exports', 'ember-cli-mdc-card/templates/components/mdc-card-action-icons'], function (exports, _mdcCardActionIcons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcCardActionIcons.default,

    classNames: ['mdc-card__action-icons']
  });
});