define('ember-cli-mdc-icon/components/mdc-icon', ['exports', 'ember-cli-mdc-icon/templates/components/mdc-icon', 'ember-cli-mdc-theme/mixins/text-theme'], function (exports, _mdcIcon, _textTheme) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_textTheme.default, {
    layout: _mdcIcon.default,

    tagName: 'i',

    classNames: ['material-icons'],

    icon: Ember.computed('params.[]', function () {
      return this.get('params')[0];
    })
  }).reopenClass({
    positionalParams: 'params'
  });
});