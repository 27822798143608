define('ember-cli-mdc-drawer/components/mdc-drawer-header', ['exports', 'ember-cli-mdc-drawer/templates/components/mdc-drawer-header'], function (exports, _mdcDrawerHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcDrawerHeader.default,

    tagName: 'header',
    classNames: ['mdc-drawer__header']
  });
});