define('ember-cli-mdc-dialog/components/mdc-dialog-surface', ['exports', 'ember-cli-mdc-dialog/templates/components/mdc-dialog-surface'], function (exports, _mdcDialogSurface) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcDialogSurface.default,

    classNames: ['mdc-dialog__surface']
  });
});