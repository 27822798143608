define('ember-cli-mdc-top-app-bar/components/mdc-top-app-bar-action-item', ['exports', 'ember-cli-mdc-top-app-bar/templates/components/mdc-top-app-bar-action-item'], function (exports, _mdcTopAppBarActionItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcTopAppBarActionItem.default,

    tagName: 'a',

    classNames: ['material-icons', 'mdc-top-app-bar__action-item'],

    attributeBindings: ['label:aria-label', 'alt'],

    label: null,

    alt: null,

    icon: null,

    didInsertElement() {
      this._super(...arguments);

      this.element.setAttribute('href', '#');
    }
  });
});