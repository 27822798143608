define('ember-cli-mdc-textfield/components/mdc-textarea-with-helper-text', ['exports', 'ember-cli-mdc-textfield/templates/components/mdc-textarea-with-helper-text', 'ember-cli-mdc-textfield/mixins/helper-text-support'], function (exports, _mdcTextareaWithHelperText, _helperTextSupport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_helperTextSupport.default, {
    layout: _mdcTextareaWithHelperText.default,

    classNames: ['mdc-text-area-with-helper-text'],

    _defaultConfig: Ember.inject.service('mdc-textarea-configurator')
  });
});