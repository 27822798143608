define('ember-cli-mdc-icon-button/components/mdc-icon-button-icon', ['exports', 'ember-cli-mdc-icon/components/mdc-icon'], function (exports, _mdcIcon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mdcIcon.default.extend({
    classNames: ['mdc-icon-button__icon'],

    classNameBindings: ['on:mdc-icon-button__icon--on'],

    on: false
  });
});