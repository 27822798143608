define('ember-cli-mdc-textfield/components/mdc-textfield-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    classNames: ['mdc-text-field__input'],

    attributeBindings: ['label:aria-label']
  });
});