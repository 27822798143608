define('ember-cli-mdc-menu/components/mdc-menu-selection-group', ['exports', 'ember-cli-mdc-menu/templates/components/mdc-menu-selection-group'], function (exports, _mdcMenuSelectionGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ul',

    layout: _mdcMenuSelectionGroup.default,

    classNames: ['mdc-menu__selection-group']
  });
});