define('ember-cli-mdc-dialog/components/mdc-dialog-container', ['exports', 'ember-cli-mdc-dialog/templates/components/mdc-dialog-container'], function (exports, _mdcDialogContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcDialogContainer.default,

    classNames: ['mdc-dialog__container']
  });
});