define('ember-cli-mdc-icon-button/components/mdc-icon-button-link-to', ['exports', 'ember-cli-mdc-ripple/mixins/ripple', 'ember-cli-mdc-icon-button/templates/components/mdc-icon-button-link-to'], function (exports, _ripple, _mdcIconButtonLinkTo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend(_ripple.default, {
    layout: _mdcIconButtonLinkTo.default,

    classNames: ['mdc-icon-button', 'material-icons'],

    createRippleComponent: true,

    unbounded: true,

    icon: Ember.computed('params.[]', function () {
      return this.get('params')[0];
    })
  });
});