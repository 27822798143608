define('ember-cli-mdc-list/components/mdc-radio-list-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    role: 'radio',

    checked: false,

    didInsertElement() {
      this._super(...arguments);

      // Set the initial checked value for the list item.
      let checked = this.get('checked');
      this.element.setAttribute('aria-checked', checked);
    }
  });
});