define('ember-cli-mdc-card/mixins/card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNames: ['mdc-card'],
    classNameBindings: ['outlined:mdc-card--outlined'],

    outlined: false
  });
});