define('ember-cli-mdc-list/components/mdc-list', ['exports', 'ember-cli-mdc-list/templates/components/mdc-list'], function (exports, _mdcList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { MDCList } = mdc.list;

  exports.default = Ember.Component.extend({
    layout: _mdcList.default,

    tagName: 'ul',

    classNames: ['mdc-list'],

    classNameBindings: ['dense:mdc-list--dense', 'interactive::mdc-list--non-interactive', 'twoLine:mdc-list--two-line', 'avatarList:mdc-list--avatar-list'],

    singleSelection: false,

    interactive: true,

    twoLine: false,

    avatarList: false,

    wrapFocus: false,

    attributeBindings: ['role', 'orientation:aria-orientation'],

    orientation: 'vertical',

    _list: null,

    didInsertElement() {
      this._super(...arguments);

      this._list = new MDCList(this.element);

      const { wrapFocus, singleSelection } = this.getProperties(['wrapFocus', 'singleSelection']);
      this._list.singleSelection = singleSelection;
      this._list.wrapFocus = wrapFocus;
    },

    willDestroyElement() {
      this._super(...arguments);

      this._list.destroy();
    },

    didUpdateAttrs() {
      this._super(...arguments);

      const { wrapFocus, singleSelection } = this.getProperties(['wrapFocus', 'singleSelection']);
      this._list.singleSelection = singleSelection;
      this._list.wrapFocus = wrapFocus;
    }
  });
});