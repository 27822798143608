define('ember-cli-mdc-typography/mixins/typography', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const TYPOGRAPHY = ['headline1', 'headline2', 'headline3', 'headline4', 'headline5', 'headline6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'caption', 'button', 'overline'];

  exports.default = Ember.Mixin.create({
    classNameBindings: ['mdcTypographyClassName'],

    typography: null,

    mdcTypographyClassName: Ember.computed('typography', function () {
      const typography = this.get('typography');

      if (Ember.isEmpty(typography)) {
        return null;
      }

      (true && !(TYPOGRAPHY.includes(typography)) && Ember.assert(`The typography must be one of the following values: ${TYPOGRAPHY}`, TYPOGRAPHY.includes(typography)));

      return `mdc-typography--${typography}`;
    })
  });
});