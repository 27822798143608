define('ember-cli-mdc-button/components/mdc-button-linkto', ['exports', 'ember-cli-mdc-button/mixins/button', 'ember-cli-mdc-button/templates/components/mdc-button'], function (exports, _button, _mdcButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend(_button.default, {
    layout: _mdcButton.default,

    createRippleComponent: true,

    label: Ember.computed.alias('params.0')
  });
});