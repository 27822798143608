define('ember-cli-mdc-list/components/mdc-list-item-graphic-icon', ['exports', 'ember-cli-mdc-list/components/mdc-list-item-graphic', 'ember-cli-mdc-list/templates/components/mdc-list-item-graphic-icon'], function (exports, _mdcListItemGraphic, _mdcListItemGraphicIcon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mdcListItemGraphic.default.extend({
    layout: _mdcListItemGraphicIcon.default,

    classNames: ['material-icons'],

    params: null,

    icon: Ember.computed('params.[]', function () {
      return this.get('params')[0];
    })
  }).reopenClass({
    positionalParams: 'params'
  });
});