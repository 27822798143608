define('ember-cli-mdc-card/components/mdc-card-actions', ['exports', 'ember-cli-mdc-card/templates/components/mdc-card-actions'], function (exports, _mdcCardActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcCardActions.default,

    classNames: ['mdc-card__actions'],

    classNameBindings: ['fullBleed:mdc-card__actions--full-bleed'],

    fullBleed: false
  });
});