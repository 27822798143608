define('ember-cli-mdc-menu/components/mdc-menu-list', ['exports', 'ember-cli-mdc-list/components/mdc-list'], function (exports, _mdcList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mdcList.default.extend({
    role: 'menu',

    attributeBindings: ['orientation:aria-orientation', 'tabindex'],

    orientation: 'vertical',

    tabindex: -1
  });
});