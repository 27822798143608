define('ember-cli-mdc-drawer/components/mdc-drawer-title', ['exports', 'ember-cli-mdc-drawer/templates/components/mdc-drawer-title'], function (exports, _mdcDrawerTitle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcDrawerTitle.default,

    tagName: 'h3',

    classNames: ['mdc-drawer__title']
  });
});