define('ember-cli-mdc-textfield/components/mdc-textfield-icon', ['exports', 'ember-cli-mdc-icon/components/mdc-icon'], function (exports, _mdcIcon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mdcIcon.default.extend({
    clickable: false,

    classNames: ['mdc-text-field__icon'],

    didRender() {
      this._super(...arguments);

      if (this.get('clickable')) {
        // Make the icon clickable.
        if (!this.element.hasAttribute('tabindex')) {
          this.element.setAttribute('tabindex', 0);
        }

        if (!this.element.hasAttribute('role')) {
          this.element.setAttribute('role', 'button');
        }
      } else {
        // Make sure the icon is not clickable.
        if (this.element.hasAttribute('tabindex')) {
          this.element.removeAttribute('tabindex');
        }

        if (this.element.hasAttribute('role')) {
          this.element.removeAttribute('role');
        }
      }
    }
  });
});