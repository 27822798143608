define('ember-cli-mdc-dialog/components/mdc-dialog-button', ['exports', 'ember-cli-mdc-button/components/mdc-button'], function (exports, _mdcButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mdcButton.default.extend({
    classNames: ['mdc-dialog__button'],

    classNameBindings: ['default:mdc-dialog__button--default'],

    attributeBindings: ['action:data-mdc-dialog-action'],

    default: false
  });
});