define('ember-cli-mdc-line-ripple/components/mdc-line-ripple', ['exports', 'ember-cli-mdc-line-ripple/templates/components/mdc-line-ripple'], function (exports, _mdcLineRipple) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcLineRipple.default,

    classNames: ['mdc-line-ripple'],

    /// Activate the line ripple.
    activate: false,

    _lineRipple: null,

    didInsertElement() {
      this._super(...arguments);

      this._lineRipple = new mdc.lineRipple.MDCLineRipple(this.element);
      this._doActivate();
    },

    didUpdateAttr() {
      this._super(...arguments);

      this._doActivate();
    },

    willDestroyElement() {
      this._super(...arguments);

      this._lineRipple.destroy();
    },

    _doActivate() {
      const activate = this.get('activate');

      if (activate) {
        this._lineRipple.activate();
      } else {
        this._lineRipple.deactivate();
      }
    }
  });
});