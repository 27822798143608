define('ember-cli-mdc-floating-label/components/mdc-floating-label', ['exports', 'ember-cli-mdc-floating-label/templates/components/mdc-floating-label'], function (exports, _mdcFloatingLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { MDCFloatingLabel } = mdc.floatingLabel;

  exports.default = Ember.Component.extend({
    layout: _mdcFloatingLabel.default,

    tagName: 'label',

    classNames: ['mdc-floating-label'],

    attributeBindings: ['for'],

    for: null,

    /// The floating label is embedded in a component that already creates a floating label.
    embedded: false,

    _floatingLabel: null,

    didInsertElement() {
      this._super(...arguments);

      let embedded = this.get('embedded');

      if (!embedded) {
        this._floatingLabel = new MDCFloatingLabel(this.element);
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      if (!!this._floatingLabel) {
        this._floatingLabel.destroy();
      }
    }
  });
});