define('ember-cli-mdc-textfield/components/mdc-textfield', ['exports', 'ember-cli-mdc-textfield/templates/components/mdc-textfield', 'ember-cli-mdc-textfield/mixins/text-support'], function (exports, _mdcTextfield, _textSupport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_textSupport.default, {
    layout: _mdcTextfield.default,

    _defaultConfig: Ember.inject.service('mdc-textfield-configurator'),

    tagName: 'div',

    classNameBindings: ['styleClassName', 'leadingIcon:mdc-text-field--with-leading-icon', 'trailingIcon:mdc-text-field--with-trailing-icon', 'noLabel:mdc-text-field--no-label'],

    // Set the style for the text field. The default style comes from the configurator.
    // To change the style, just set this value when adding the component to handlebars.
    style: Ember.computed.oneWay('_defaultConfig.style'),

    label: null,
    helperText: null,
    disabled: false,

    isOutlined: Ember.computed.equal('style', 'outlined'),
    isFullWidth: Ember.computed.equal('style', 'fullwidth'),

    hasNotchedOutline: Ember.computed.or('isOutlined', 'isFullWidth'),

    styleClassName: Ember.computed('style', function () {
      const style = this.get('style');

      if (Ember.isEmpty(style) || style === 'standard') {
        return null;
      }

      return `mdc-text-field--${style}`;
    }),

    inputId: Ember.computed(function () {
      return `${this.elementId}-input`;
    }),

    // Reference to the floating label. There are cases where we need to manage
    // its state due to the possibility of the text fields value being dynamic
    // updated by some external source.
    _input: null,

    didCreateComponent() {
      this._super(...arguments);

      this._input = this.element.querySelector('input');
    },

    willDestroyComponent() {
      this._input = undefined;
    },

    _getNativeInput() {
      return this._input;
    }
  });
});