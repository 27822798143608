define('ember-cli-custom-properties/mixins/custom-properties', ['exports', 'ember-cli-custom-properties/-private/custom-property'], function (exports, _customProperty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const EMPTY_ARRAY = Object.freeze([]);

  exports.default = Ember.Mixin.create({
    concatProperties: ['customProperties', 'customPropertyBindings'],

    _customProperties: null,

    customProperties: EMPTY_ARRAY,
    customPropertyBindings: EMPTY_ARRAY,

    init() {
      this._super(...arguments);

      // Initialize the custom properties.
      this._initCustomProperties();
    },

    _initCustomProperties() {
      let {
        customProperties,
        customPropertyBindings
      } = this.getProperties(['customProperties', 'customPropertyBindings']);

      // Build an array of custom properties that we are observing.
      let bindings = customProperties.reduce((bindings, prop) => {
        const name = `--${Ember.String.dasherize(prop)}`;
        bindings.push(_customProperty.default.create({ prop, name, component: this }));

        return bindings;
      }, []);

      bindings = customPropertyBindings.reduce((bindings, mapping) => {
        let [prop, name] = mapping.split(':');
        bindings.push(_customProperty.default.create({ prop, name, component: this }));

        return bindings;
      }, bindings);

      this._customProperties = bindings;
    },

    willDestroyElement() {
      this._super(...arguments);

      for (let i = 0, len = this._customProperties.length; i < len; ++i) {
        this._customProperties[i].destroy();
      }
    }
  });
});