define('ember-cli-mdc-top-app-bar/components/mdc-top-app-bar-section', ['exports', 'ember-cli-mdc-top-app-bar/templates/components/mdc-top-app-bar-section'], function (exports, _mdcTopAppBarSection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const POSITIONS = ['alignStart', 'alignEnd'];

  exports.default = Ember.Component.extend({
    layout: _mdcTopAppBarSection.default,

    tagName: 'section',

    classNames: ['mdc-top-app-bar__section'],

    classNameBindings: ['positionClassName'],

    attributeBindings: ['role'],

    position: null,

    positionClassName: Ember.computed('position', function () {
      const position = this.get('position');

      if (Ember.isNone(position)) {
        return null;
      }

      (true && !(POSITIONS.includes(position)) && Ember.assert('The position property must be one of the following values: alignStart, alignEnd.', POSITIONS.includes(position)));

      return `mdc-top-app-bar__section--${Ember.String.dasherize(position)}`;
    })
  });
});