define('ember-cli-mdc-card/components/mdc-card-action-icon', ['exports', 'ember-cli-mdc-card/mixins/card-action', 'ember-cli-mdc-icon-button/components/mdc-icon-button'], function (exports, _cardAction, _mdcIconButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mdcIconButton.default.extend(_cardAction.default, {
    classNames: ['mdc-card__action--icon'],

    attributeBindings: ['title'],

    title: null
  });
});