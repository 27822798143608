define('ember-cli-mdc-ripple/mixins/ripple', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { MDCRipple } = mdc.ripple;

  exports.default = Ember.Mixin.create({
    classNames: ['mdc-ripple-surface'],

    classNameBindings: ['mdcRippleColorClassName'],

    attributeBindings: ['unbounded:data-mdc-ripple-is-unbounded'],

    mdcRippleColorClassName: Ember.computed('rippleColor', function () {
      const rippleColor = this.get('rippleColor');
      return Ember.isPresent(rippleColor) ? `mdc-ripple-surface--${rippleColor}` : null;
    }),

    // Set the ripple color. The acceptable values are "primary" or "accent".
    rippleColor: null,

    /// The instance of the MDCRipple component.
    _ripple: null,

    /// Force the creation of the MDC ripple component. This is only required if the
    /// target MDC component does not create a ripple component.
    createRippleComponent: false,

    didInsertElement() {
      this._super(...arguments);

      if (this.createRippleComponent) {
        this._ripple = new MDCRipple(this.element);
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      if (!!this._ripple) {
        this._ripple.destroy();
      }
    }
  });
});