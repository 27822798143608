define('ember-cli-mdc-form-field/mixins/form-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNames: ['mdc-form-field'],
    classNameBindings: ['alignEnd:mdc-form-field--align-end'],

    /// The mdc component.
    _formField: null,

    didInsertElement() {
      this._super(...arguments);

      this._formField = new mdc.formField.MDCFormField(this.element);
    },

    willDestroyElement() {
      this._super(...arguments);

      this._formField.destroy();
    }
  });
});