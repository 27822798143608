define('ember-cli-mdc-button/components/mdc-button-icon', ['exports', 'ember-cli-mdc-icon/components/mdc-icon'], function (exports, _mdcIcon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mdcIcon.default.extend({
    classNames: ['mdc-button__icon'],

    didInsertElement() {
      this._super(...arguments);

      this.element.setAttribute('aria-hidden', true);
    }
  });
});