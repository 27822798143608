define('ember-cli-mdc-notched-outline/components/mdc-notched-outline-leading', ['exports', 'ember-cli-mdc-notched-outline/templates/components/mdc-notched-outline-leading'], function (exports, _mdcNotchedOutlineLeading) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcNotchedOutlineLeading.default,

    classNames: ['mdc-notched-outline__leading']
  });
});