define('ember-cli-mdc-top-app-bar/components/mdc-top-app-bar-navigation-icon', ['exports', 'ember-cli-mdc-top-app-bar/templates/components/mdc-top-app-bar-navigation-icon'], function (exports, _mdcTopAppBarNavigationIcon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcTopAppBarNavigationIcon.default,

    tagName: 'a',

    classNames: ['mdc-top-app-bar__navigation-icon', 'material-icons'],

    icon: 'menu',

    didInsertElement() {
      this._super(...arguments);

      this.element.setAttribute('href', '#');
    }
  });
});