define('ember-cli-mdc-list/components/mdc-list-item-graphic', ['exports', 'ember-cli-mdc-list/templates/components/mdc-list-item-graphic'], function (exports, _mdcListItemGraphic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcListItemGraphic.default,

    tagName: 'span',

    classNames: ['mdc-list-item__graphic'],

    didInsertElement() {
      this._super(...arguments);

      this.element.setAttribute('aria-hidden', true);
    }
  });
});