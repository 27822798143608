define('ember-cli-mdc-card/components/mdc-card-action-buttons', ['exports', 'ember-cli-mdc-card/templates/components/mdc-card-action-buttons'], function (exports, _mdcCardActionButtons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcCardActionButtons.default,

    classNames: ['mdc-card__action-buttons']
  });
});