define('ember-cli-mdc-theme/mixins/surface-theme', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    customPropertyBindings: ['themeSurfaceColor:--mdc-theme-surface'],

    classNameBindings: ['themeSurface:mdc-theme--surface']
  });
});