define('ember-cli-mdc-card/components/mdc-card-primary-action', ['exports', 'ember-cli-mdc-card/templates/components/mdc-card-primary-action'], function (exports, _mdcCardPrimaryAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcCardPrimaryAction.default,

    classNames: ['mdc-card__primary-action'],

    didInsertElement() {
      this._super(...arguments);

      this.element.setAttribute('tabindex', 0);
    }
  });
});