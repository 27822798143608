define('ember-cli-mdc-linear-progress/components/mdc-linear-progress-bar-inner', ['exports', 'ember-cli-mdc-linear-progress/templates/components/mdc-linear-progress-bar-inner'], function (exports, _mdcLinearProgressBarInner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcLinearProgressBarInner.default,

    tagName: 'span',

    classNames: ['mdc-linear-progress__bar-inner']
  });
});