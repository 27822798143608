define('ember-cli-mdc-top-app-bar/components/mdc-top-app-bar', ['exports', 'ember-cli-mdc-theme/mixins/theme', 'ember-cli-mdc-top-app-bar/templates/components/mdc-top-app-bar'], function (exports, _theme, _mdcTopAppBar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const MDCTopAppBar = mdc.topAppBar.MDCTopAppBar;
  const STYLES = ['fixed', 'dense', 'prominent', 'short'];

  function noOp() {}

  exports.default = Ember.Component.extend(_theme.default, {
    layout: _mdcTopAppBar.default,

    tagName: 'header',

    classNames: 'mdc-top-app-bar',

    classNameBindings: ['styleClassName', 'fixedAdjustClassName', 'alwaysClosedClassName:mdc-top-app-bar--short-collapsed', 'contextual:mdc-top-app-bar--contextual'],

    /// Notification for navigation button clicked.
    navigation: undefined,

    /// The top app bar is always closed in short mode.
    alwaysClosed: false,

    /// Mark the top app bar as contextual
    contextual: false,

    _topAppBar: undefined,
    _navEventListener: undefined,

    style: null,
    styleClassName: Ember.computed('style', function () {
      const style = this.get('style');

      // Notify the listeners that our style has changed.
      if (this._topAppBar) {
        this._topAppBar.emit('MDCTopAppBar:change', { style }, true);
      }

      if (Ember.isEmpty(style)) {
        return null;
      }

      (true && !(STYLES.includes(style)) && Ember.assert(`The style attribute must be one of the following values: ${STYLES}`, STYLES.includes(style)));

      return `mdc-top-app-bar--${style}`;
    }),

    alwaysClosedClassName: Ember.computed('{alwaysClosed,style}', function () {
      const { alwaysClosed, style } = this.getProperties(['style', 'alwaysClosed']);
      return alwaysClosed && style === 'short' ? 'mdc-top-app-bar--short-collapsed' : null;
    }),

    init() {
      this._super(...arguments);

      this._navEventListener = this.doNavigation.bind(this);
    },

    didInsertElement() {
      this._super(...arguments);
      this._createComponent();

      // Notify the listeners of the original style.
      let style = this.get('style');
      this._topAppBar.emit('MDCTopAppBar:change', { style }, true);
    },

    didUpdate() {
      this._super(...arguments);

      this._destroyComponent();
      this._createComponent();
    },

    didRender() {
      this._super(...arguments);

      // Set the scroll target for the top app bar. We process it after the component
      // has rendered because we need to make sure all elements, including those for
      // its children, are part of the dom model.
      let scrollTarget = this.get('scrollTarget');

      if (Ember.isPresent(scrollTarget)) {
        this.setScrollTarget(scrollTarget);
      }
    },

    willDestroyElement() {
      this._super(...arguments);
      this._destroyComponent();
    },

    doNavigation() {
      this.getWithDefault('navigation', noOp)();
    },

    setScrollTarget(scrollTarget) {
      let element = document.querySelector(scrollTarget);

      if (Ember.isPresent(element)) {
        this._topAppBar.setScrollTarget(element);
      }
    },

    _createComponent() {
      this._topAppBar = new MDCTopAppBar(this.element);

      this._topAppBar.listen('MDCTopAppBar:nav', this._navEventListener);
    },

    _destroyComponent() {
      this._topAppBar.unlisten('MDCTopAppBar:nav', this._navEventListener);
      this._topAppBar.destroy();
    }
  });
});