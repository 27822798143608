define('ember-cli-mdc-linear-progress/components/mdc-linear-progress-buffering-dots', ['exports', 'ember-cli-mdc-linear-progress/templates/components/mdc-linear-progress-buffering-dots'], function (exports, _mdcLinearProgressBufferingDots) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcLinearProgressBufferingDots.default,

    classNames: ['mdc-linear-progress__buffering-dots']
  });
});