define('ember-cli-mdc-fab/components/mdc-fab', ['exports', 'ember-cli-mdc-fab/templates/components/mdc-fab', 'ember-cli-mdc-fab/mixins/fab'], function (exports, _mdcFab, _fab) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fab.default, {
    layout: _mdcFab.default,

    tagName: 'button'
  });
});