define('ember-cli-mdc-list/components/mdc-list-item', ['exports', 'ember-cli-mdc-list/mixins/list-item', 'ember-cli-mdc-list/templates/components/mdc-list-item'], function (exports, _listItem, _mdcListItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_listItem.default, {
    layout: _mdcListItem.default,

    tagName: 'li'
  });
});