define('ember-cli-mdc-top-app-bar/components/mdc-top-app-bar-title', ['exports', 'ember-cli-mdc-top-app-bar/templates/components/mdc-top-app-bar-title'], function (exports, _mdcTopAppBarTitle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcTopAppBarTitle.default,

    tagName: 'span',

    classNames: ['mdc-top-app-bar__title']
  });
});