define('ember-cli-mdc-drawer/components/mdc-drawer-toolbar-spacer', ['exports', 'ember-cli-mdc-drawer/templates/components/mdc-drawer-toolbar-spacer'], function (exports, _mdcDrawerToolbarSpacer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcDrawerToolbarSpacer.default,

    classNames: ['mdc-drawer__toolbar-spacer']
  });
});