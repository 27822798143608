define('ember-cli-mdc-typography/instance-initializers/typography', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(app) {
    // Make sure the window and document element exists. If neither exists, then
    // we are running in FastBoot.
    if (Ember.isNone(window) || Ember.isNone(window.document)) return;

    // Load the typography configuration, and use it to initialize the
    // typography for the application.

    const ENV = app.application.resolveRegistration('config:environment');
    const config = Ember.getWithDefault(ENV, 'ember-cli-mdc.typography', {});

    const { disabled = false } = config;

    if (!disabled) {
      const rootElement = document.querySelector(app.rootElement);

      if (Ember.isPresent(rootElement)) {
        rootElement.classList.add('mdc-typography');
      }
    }
  }

  exports.default = {
    initialize
  };
});