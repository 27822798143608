define('ember-cli-mdc-textfield/components/mdc-textarea', ['exports', 'ember-cli-mdc-textfield/templates/components/mdc-textarea', 'ember-cli-mdc-textfield/mixins/text-support'], function (exports, _mdcTextarea, _textSupport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_textSupport.default, {
    layout: _mdcTextarea.default,

    classNames: ['mdc-text-field--textarea'],
    classNameBindings: ['fullWidth:mdc-text-field--fullwidth'],

    fullWidth: false,
    notFullWidth: Ember.computed.not('fullWidth'),

    _defaultConfig: Ember.inject.service('mdc-textarea-configurator'),

    textAreaId: Ember.computed(function () {
      return `${this.elementId}-textarea`;
    }),

    // Reference to the floating label. There are cases where we need to manage
    // its state due to the possibility of the text fields value being dynamic
    // updated by some external source.
    _textarea: null,

    didCreateComponent() {
      this._super(...arguments);

      this._textarea = this.element.querySelector('textarea');
    },

    willDestroyComponent() {
      this._textarea = undefined;
    },

    _getNativeInput() {
      return this._textarea;
    }
  });
});