define('ember-cli-mdc-dialog/components/mdc-dialog-actions', ['exports', 'ember-cli-mdc-dialog/templates/components/mdc-dialog-actions'], function (exports, _mdcDialogActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcDialogActions.default,

    classNames: ['mdc-dialog__actions'],

    tagName: 'footer'
  });
});