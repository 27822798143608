define('ember-cli-mdc-elevation/mixins/elevation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNameBindings: ['mdcElevationClassName', 'elevationTransition:mdc-elevation-transition'],

    mdcElevationClassName: Ember.computed('elevation', function () {
      const elevation = this.get('elevation');
      return Ember.isPresent(elevation) ? `mdc-elevation--z${elevation}` : null;
    }),

    elevation: null
  });
});