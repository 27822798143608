define('ember-cli-mdc-menu/components/mdc-menu-selection-group-icon', ['exports', 'ember-cli-mdc-menu/templates/components/mdc-menu-selection-group-icon'], function (exports, _mdcMenuSelectionGroupIcon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcMenuSelectionGroupIcon.default,

    tagName: 'span',

    classNames: ['mdc-menu__selection-group-icon']
  });
});