define('ember-cli-mdc-checkbox/components/mdc-checkbox', ['exports', 'ember-cli-mdc-checkbox/templates/components/mdc-checkbox'], function (exports, _mdcCheckbox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function noOp() {}

  exports.default = Ember.Component.extend({
    layout: _mdcCheckbox.default,

    classNames: ['mdc-checkbox'],

    _checkbox: null,

    didInsertElement() {
      this._super(...arguments);

      this._checkbox = new mdc.checkbox.MDCCheckbox(this.element);
      this.getWithDefault('initialized', noOp)(this._checkbox);
    }
  });
});