define('ember-cli-mdc-notched-outline/components/mdc-notched-outline-notch', ['exports', 'ember-cli-mdc-notched-outline/templates/components/mdc-notched-outline-notch'], function (exports, _mdcNotchedOutlineNotch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcNotchedOutlineNotch.default,

    classNames: ['mdc-notched-outline__notch']
  });
});