define('ember-cli-mdc-list/components/mdc-list-group-subheader', ['exports', 'ember-cli-mdc-list/templates/components/mdc-list-group-subheader'], function (exports, _mdcListGroupSubheader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcListGroupSubheader.default,

    tagName: 'h3',

    classNames: ['mdc-list-group__subheader']
  });
});