define('ember-cli-mdc-list/components/mdc-list-group', ['exports', 'ember-cli-mdc-list/templates/components/mdc-list-group'], function (exports, _mdcListGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcListGroup.default,

    classNames: ['mdc-list-group']
  });
});