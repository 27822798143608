define('ember-cli-mdc-textfield/services/mdc-textarea-configurator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init() {
      this._super(...arguments);
      this._loadConfiguration();
    },

    _loadConfiguration() {}
  });
});