define('ember-cli-mdc-list/components/mdc-list-divider', ['exports', 'ember-cli-mdc-list/templates/components/mdc-list-divider'], function (exports, _mdcListDivider) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcListDivider.default,

    classNames: ['mdc-list-divider'],

    classNameBindings: ['padded:mdc-list-divider--padded', 'inset:mdc-list-divider--inset'],

    padded: false,

    inset: false,

    didInsertElement() {
      this._super(...arguments);

      this.element.setAttribute('role', 'separator');
    }
  });
});