define('ember-cli-mdc-fab/mixins/fab', ['exports', 'ember-cli-mdc-ripple/mixins/ripple', 'ember-cli-mdc-theme/mixins/theme'], function (exports, _ripple, _theme) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_ripple.default, _theme.default, {
    classNames: ['mdc-fab'],

    classNameBindings: ['label:mdc-fab--extended', 'mini:mdc-fab--mini', 'exited:mdc-fab--exited'],

    attributeBindings: ['label:aria-label', 'type'],

    /// Icon for the FAB.
    icon: null,

    /// Optional label for the FAB.
    label: null,

    /// The FAB is an extended.
    labelPosition: 'right',

    labelLeft: Ember.computed.equal('labelPosition', 'left'),

    extendedLeft: Ember.computed.and('label', 'labelLeft'),

    labelRight: Ember.computed.equal('labelPosition', 'right'),

    extendedRight: Ember.computed.and('label', 'labelRight'),

    createRippleComponent: true
  });
});