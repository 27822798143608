define('ember-cli-mdc-textfield/components/mdc-textfield-with-helper-text', ['exports', 'ember-cli-mdc-textfield/templates/components/mdc-textfield-with-helper-text', 'ember-cli-mdc-textfield/mixins/helper-text-support'], function (exports, _mdcTextfieldWithHelperText, _helperTextSupport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_helperTextSupport.default, {
    layout: _mdcTextfieldWithHelperText.default,

    classNames: ['mdc-text-field-with-helper-text'],

    // {{mdc-textfield}}
    // Set the style for the text field. The default style comes from the configurator.
    // To change the style, just set this value when adding the component to handlebars.
    style: Ember.computed.oneWay('_defaultConfig.style'),

    label: null,
    disabled: false,

    icon: null,
    iconPosition: null,
    iconClickable: false,
    iconClick: null,

    _defaultConfig: Ember.inject.service('mdc-textfield-configurator')
  });
});