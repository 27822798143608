define('ember-cli-mdc-theme/mixins/background-theme', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const THEME_BACKGROUND_VALUES = ['background', 'primary', 'secondary'];

  exports.default = Ember.Mixin.create({
    customPropertyBindings: ['themeBackgroundColor:--mdc-theme-background'],

    classNameBindings: ['mdcThemeBackgroundClassName'],

    /**
     * Sets the background color to the selected style. The `themeBackground` attribute must
     * be one of the following values:
     *
     *  = "background"
     *  = "primary"
     *  = "secondary"
     */
    themeBackground: null,

    mdcThemeBackgroundClassName: Ember.computed('themeBackground', function () {
      const themeBackground = this.get('themeBackground');

      if (Ember.isEmpty(themeBackground)) {
        return null;
      }

      (true && !(THEME_BACKGROUND_VALUES.includes(themeBackground)) && Ember.assert(`The themeBackground attribute must be one of the following values: ${THEME_BACKGROUND_VALUES}`, THEME_BACKGROUND_VALUES.includes(themeBackground)));

      return themeBackground === 'background' ? 'mdc-theme--background' : `mdc-theme--${themeBackground}-bg`;
    })
  });
});