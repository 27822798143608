define('ember-cli-mdc-checkbox/components/mdc-checkbox-with-label', ['exports', 'ember-cli-mdc-form-field/components/mdc-form-field', 'ember-cli-mdc-checkbox/templates/components/mdc-checkbox-with-label'], function (exports, _mdcFormField, _mdcCheckboxWithLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mdcFormField.default.extend({
    layout: _mdcCheckboxWithLabel.default,

    classNames: ['mdc-checkbox-with-label'],

    didInsertElement() {
      this._super(...arguments);

      // Associate the label with the input control.
      const input = this.element.querySelector('input');
      const label = this.element.querySelector('label');
      label.setAttribute('for', input.id);

      // Associate the checkbox input with the form field.
      this._formField.input = this.get('input');
    }
  });
});