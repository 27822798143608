define('ember-cli-mdc-textfield/services/mdc-textfield-configurator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    /// The default style of all text fields.
    style: null,

    init() {
      this._super(...arguments);
      this._loadConfiguration();
    },

    _loadConfiguration() {
      const ENV = Ember.getOwner(this).resolveRegistration('config:environment');
      const config = Ember.getWithDefault(ENV, 'ember-cli-mdc', {});
      const inputConfig = Ember.getWithDefault(config, 'input', {});
      const textFieldConfig = Ember.getWithDefault(config, 'textfield', {});

      this.setProperties({
        style: textFieldConfig.style || inputConfig.style || null
      });
    }
  });
});