define('ember-cli-mdc-linear-progress/components/mdc-linear-progress-buffer', ['exports', 'ember-cli-mdc-linear-progress/templates/components/mdc-linear-progress-buffer'], function (exports, _mdcLinearProgressBuffer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcLinearProgressBuffer.default,

    classNames: ['mdc-linear-progress__buffer']
  });
});