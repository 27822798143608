define('ember-cli-mdc-icon-button/components/mdc-icon-button', ['exports', 'ember-cli-mdc-ripple/mixins/ripple', 'ember-cli-mdc-icon-button/templates/components/mdc-icon-button'], function (exports, _ripple, _mdcIconButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_ripple.default, {
    layout: _mdcIconButton.default,

    tagName: 'button',

    classNames: ['mdc-icon-button', 'material-icons'],

    attributeBindings: ['disabled', 'value', 'type', 'form'],

    createRippleComponent: true,
    unbounded: true,

    icon: Ember.computed('params.[]', function () {
      return this.get('params')[0];
    })
  }).reopenClass({
    positionalParams: 'params'
  });
});