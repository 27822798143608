define('ember-cli-custom-properties/-private/custom-property', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.CoreObject.extend({
    /// The component to apply the custom property.
    component: null,

    /// The property name in the component.
    prop: null,

    /// The target custom property name (i.e., css variable).
    name: null,

    init() {
      this._super(...arguments);

      // Observe changes to the components value.
      Ember.addObserver(this.component, this.prop, this, this.refresh);

      // Apply the initial value.
      this.refresh();
    },

    destroy() {
      Ember.removeObserver(this.component, this.prop, this, this.refresh);
    },

    refresh() {
      const value = this.component.get(this.prop);

      Ember.RSVP.resolve(value).then(value => {
        // The property value changed. We need to update the style property to reflect
        // the changes. Make sure to save the new value for the next change.
        const { element } = this.component;

        if (Ember.isPresent(value)) {
          element.style.setProperty(this.name, value);
        } else {
          element.style.removeProperty(this.name);
        }
      });
    }
  });
});