define('ember-cli-mdc-drawer/components/mdc-drawer-content', ['exports', 'ember-cli-mdc-drawer/templates/components/mdc-drawer-content'], function (exports, _mdcDrawerContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcDrawerContent.default,

    tagName: 'nav',

    classNames: ['mdc-drawer__content']
  });
});