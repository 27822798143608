define('ember-cli-mdc-drawer/components/mdc-drawer-permanent', ['exports', 'ember-cli-mdc-drawer/templates/components/mdc-drawer-permanent', 'ember-cli-mdc-drawer/mixins/drawer'], function (exports, _mdcDrawerPermanent, _drawer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_drawer.default, {
    layout: _mdcDrawerPermanent.default,

    tagName: 'nav',
    classNames: ['mdc-drawer--permanent']
  });
});