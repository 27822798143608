define('ember-cli-mdc-dialog/components/mdc-dialog-content', ['exports', 'ember-cli-mdc-dialog/templates/components/mdc-dialog-content'], function (exports, _mdcDialogContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcDialogContent.default,

    classNames: ['mdc-dialog__content']
  });
});