define('ember-cli-mdc-menu/mixins/menu-item', ['exports', 'ember-cli-mdc-list/mixins/list-item'], function (exports, _listItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_listItem.default, {
    classNames: ['mdc-menu-item'],

    classNameBindings: ['selected:mdc-menu-item--selected'],

    selected: false,

    role: 'menuitem'
  });
});