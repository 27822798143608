define('ember-cli-mdc-list/components/mdc-list-item-text', ['exports', 'ember-cli-mdc-list/templates/components/mdc-list-item-text'], function (exports, _mdcListItemText) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcListItemText.default,

    tagName: 'span',

    classNames: ['mdc-list-item__text']
  });
});