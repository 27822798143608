define('ember-cli-mdc-notched-outline/components/mdc-notched-outline', ['exports', 'ember-cli-mdc-notched-outline/templates/components/mdc-notched-outline'], function (exports, _mdcNotchedOutline) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { MDCNotchedOutline } = mdc.notchedOutline;

  exports.default = Ember.Component.extend({
    layout: _mdcNotchedOutline.default,

    classNames: ['mdc-notched-outline'],
    classNameBindings: ['notched:mdc-notched-outline--notched'],

    _notchedOutline: null,

    didInsertElement() {
      this._super(...arguments);

      this._notchedOutline = new MDCNotchedOutline(this.element);
    },

    willDestroyElement() {
      this._super(...arguments);

      // Destroy the component, and then remove the notched outline idle element
      // we added when the component element was inserted into the DOM.

      this._notchedOutline.destroy();
    }
  });
});