define('ember-cli-custom-properties/initializers/custom-properties', ['exports', 'ember-cli-custom-properties/mixins/custom-properties'], function (exports, _customProperties) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    Ember.Component.reopen(_customProperties.default);
  }

  exports.default = {
    initialize
  };
});