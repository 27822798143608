define('ember-cli-mdc-textfield/components/mdc-password-textfield-with-helper-text', ['exports', 'ember-cli-mdc-textfield/templates/components/mdc-password-textfield-with-helper-text', 'ember-cli-mdc-textfield/mixins/helper-text-support'], function (exports, _mdcPasswordTextfieldWithHelperText, _helperTextSupport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_helperTextSupport.default, {
    layout: _mdcPasswordTextfieldWithHelperText.default,

    classNames: ['mdc-text-field--password'],

    showPassword: false,

    iconOn: 'visibility',

    iconOff: 'visibility_off',

    actions: {
      toggle() {
        this.toggleProperty('showPassword');
      }
    }
  });
});