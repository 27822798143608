define('ember-cli-mdc-button/mixins/button', ['exports', 'ember-cli-mdc-ripple/mixins/ripple', 'ember-cli-mdc-theme/mixins/theme'], function (exports, _ripple, _theme) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_theme.default, _ripple.default, {
    classNames: ['mdc-button'],

    classNameBindings: ['mdcStyleClassName', 'dense:mdc-button--dense'],

    attributeBindings: ['disabled', 'value', 'type', 'form', 'title'],

    dense: false,

    mdcStyleClassName: Ember.computed('style', function () {
      const style = this.get('style');
      return Ember.isPresent(style) ? `mdc-button--${style}` : null;
    })
  });
});