define('ember-cli-mdc-notched-outline/components/mdc-notched-outline-trailing', ['exports', 'ember-cli-mdc-notched-outline/templates/components/mdc-notched-outline-trailing'], function (exports, _mdcNotchedOutlineTrailing) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mdcNotchedOutlineTrailing.default,

    classNames: ['mdc-notched-outline__trailing']
  });
});