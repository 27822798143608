define('ember-cli-mdc-drawer/components/mdc-drawer-app-content', ['exports', 'ember-cli-mdc-drawer/templates/components/mdc-drawer-app-content'], function (exports, _mdcDrawerAppContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const MDC_DRAWER_STYLE_REGEXP = /mdc-drawer--(.*)/;

  exports.default = Ember.Component.extend({
    layout: _mdcDrawerAppContent.default,

    classNameBindings: ['isDismissible:mdc-drawer-app-content'],

    _drawerStyle: null,

    isModal: Ember.computed.equal('_drawerStyle', 'modal'),
    isDismissible: Ember.computed.equal('_drawerStyle', 'dismissible'),
    isPermanent: Ember.computed.equal('_drawerStyle', 'permanent'),

    _drawer: null,
    _drawerChangeListener: null,

    init() {
      this._super(...arguments);

      this._drawerChangeListener = this.didDrawerChange.bind(this);
    },

    didInsertElement() {
      this._super(...arguments);

      // Locate the top app bar component, and listen for changes.
      this._drawer = document.querySelector('.mdc-drawer');

      if (Ember.isPresent(this._drawer)) {
        let style = this._getDrawerStyle();
        this.set('_drawerStyle', style);

        this._drawer.addEventListener('MDCDrawer:change', this._drawerChangeListener);
      } else {
        console.warn('This component needs a {{mdc-drawer}} component to work.');
      }
    },

    didDrawerChange({ detail: { style } }) {
      this.set('_drawerStyle', style);
    },

    _getDrawerStyle() {
      for (let i = 0, len = this._drawer.classList.length; i < len; ++i) {
        const className = this._drawer.classList.item(i);
        const matches = className.match(MDC_DRAWER_STYLE_REGEXP);

        if (matches) {
          return matches[1];
        }
      }
    }
  });
});