define('ember-cli-mdc-menu/components/mdc-menu-item', ['exports', 'ember-cli-mdc-list/components/mdc-list-item', 'ember-cli-mdc-menu/mixins/menu-item', 'ember-cli-mdc-menu/templates/components/mdc-menu-item'], function (exports, _mdcListItem, _menuItem, _mdcMenuItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mdcListItem.default.extend(_menuItem.default, {
    layout: _mdcMenuItem.default,

    text: Ember.computed('params.[]', function () {
      return this.get('params')[0];
    })
  }).reopenClass({
    positionalParams: 'params'
  });
});